<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Vendors
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left booking_content_wrapper mb-2"
        cols="12"
        md="12"
      >
        <b-col
          class="content-header-right mb-2 ml-auto"
          cols="12"
          md="12"
        >
          <b-button
            v-if="canViewThisAction('create', 'Vendor')"
            variant="outline-success"
            class="mobile_create_button list-page-extra-button mr-50"
            @click="generateOnlineLink"
          >
            <span class="align-middle d-md-block d-none">Create an Online Link</span>
            <span class="align-middle d-md-none d-block">Online Link</span>
          </b-button>
          <b-button
            v-if="canViewThisAction('create', 'Vendor')"
            variant="success"
            :to="{ name: 'purchasing-vendors-create' }"
            class="mobile_create_button"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle d-md-block d-none">Create New Vendor</span>
            <span class="align-middle d-md-none d-block">New</span>
          </b-button>
        </b-col>
        <b-dropdown
          v-if="isMobile()"
          :text="currentTabTitle"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="(tab, index) in pageTabs"
            :key="index"
            @click="changeRoute(tab.param)"
          >
            {{ tab.title }} ({{ tab.count }})
          </b-dropdown-item>
        </b-dropdown>
        <b-tabs
          pills
          class="booking__content"
          :nav-class="[isMobile() ? 'd-none' : '']"
        >
          <!-- Tab: Account -->
          <b-tab
            :active="$route.params.type == 'approved'"
            lazy
            @click="changeRoute('approved')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Approved <span class="rounded-number">{{ approvedCount }}</span></span>
            </template>
            <approved-vendor
              class="mt-2 pt-75"
              @emitCountVendor="countVendors"
            />
          </b-tab>

          <!-- Tab: Information -->
          <b-tab
            :active="$route.params.type == 'pending'"
            lazy
            @click="changeRoute('pending')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Pending <span class="rounded-number">{{ pendingCount }}</span></span>
            </template>
            <pending-vendor
              class="mt-2 pt-75"
              @emitCountVendor="countVendors"
            />
          </b-tab>

          <b-tab
            :active="$route.params.type == 'non-approved'"
            lazy
            @click="changeRoute('non-approved')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Non Approved <span class="rounded-number">{{ nonApprovedCount }}</span></span>
            </template>
            <non-approved-vendor
              class="mt-2 pt-75"
              @emitCountVendor="countVendors"
            />
          </b-tab>

          <b-tab
            :active="$route.params.type == 'all-vendors'"
            lazy
            @click="changeRoute('all-vendors')"
          >
            <template #title>
              <span class="d-none d-sm-inline">All Vendors <span class="rounded-number">{{ allCount }}</span></span>
            </template>
            <all-vendor
              class="mt-2 pt-75"
              @emitCountVendor="countVendors"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BButton, BCol, BTab, BTabs, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AllVendor from './all-vendor/AllVendor.vue'
import PendingVendor from './pending-vendor/PendingVendor.vue'
import ApprovedVendor from './approved-vendor/ApprovedVendor.vue'
import NonApprovedVendor from './non-approved-vendor/NonApprovedVendor.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTab,
    BTabs,
    BDropdown,
    BDropdownItem,

    AllVendor,
    PendingVendor,
    ApprovedVendor,
    NonApprovedVendor,
  },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      allCount: 0,
      pendingCount: 0,
      approvedCount: 0,
      nonApprovedCount: 0,
      currentTabTitle: '',
      pageTabs: [
        {
          title: 'Approved',
          param: 'approved',
          count: 0,
        },
        {
          title: 'Pending',
          param: 'pending',
          count: 0,
        },
        {
          title: 'Non Approved',
          param: 'non-approved',
          count: 0,
        },
        {
          title: 'All Vendors',
          param: 'all-vendors',
          count: 0,
        },
      ],
    }
  },
  watch: {
    $route() {
      const param = this.$route.params.type
      const currentTab = this.pageTabs.find(o => o.param === param)
      if (currentTab) {
        this.currentTabTitle = `${currentTab.title} (${currentTab.count})`
      }
    },
  },
  beforeMount() {
    this.countVendors()
  },

  methods: {
    changeRoute(param) {
      this.$router.replace({
        params: {
          type: param,
        },
        query: {},
      })
    },
    generateOnlineLink() {
      const link = `${window.location.origin}/vendor/self-registration?referrer=${this.user._id}`
      this.$swal({
        title: 'Link Generated',
        html: `The vendor can now fill up the New Vendor Registration Form by accessing this link below: <br> ${link}`,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/link.png'),
        showCancelButton: false,
        confirmButtonText: 'Copy Link',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        allowOutsideClick: false,
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$copyText(link).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Text copied',
                  icon: 'BellIcon',
                },
              })
            }, () => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Can not copy!',
                  icon: 'BellIcon',
                },
              })
            })
          }
        })
    },
    countVendors() {
      this.$http.get('purchase/vendors/counts')
        .then(response => {
          this.allCount = response.data.allCount
          this.pendingCount = response.data.pendingCount
          this.approvedCount = response.data.approvedCount
          this.nonApprovedCount = response.data.nonApprovedCount
          this.pageTabs.forEach(element => {
            if (element.title === 'All Vendors') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.allCount
            } else if (element.title === 'Approved') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.approvedCount
            } else if (element.title === 'Pending') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.pendingCount
            } else if (element.title === 'Non Approved') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.nonApprovedCount
            }
          })

          const param = this.$route.params.type

          const currentTab = this.pageTabs.find(o => o.param === param)
          if (currentTab) {
            this.currentTabTitle = `${currentTab.title} (${currentTab.count})`
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style scoped>
  .rounded-number {
    height: 24px;
    width: 24px;
    background-color: #FFFFFF;
    border-radius: 50%;
    color:#104D9D;
  }
</style>
