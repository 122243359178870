import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useVendorList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refApprovedVendorsTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'stringID',
      label: 'Vendor ID',
      sortable: true,
    },
    {
      key: 'companyName',
      label: 'Company Name',
      sortable: true,
    },
    {
      key: 'providedServiceType',
      label: 'Nature of Business',
      sortable: true,
    },
    {
      key: 'createdAt',
      label: 'created on',
      sortable: true,
    },
    {
      key: 'lastOrder',
      label: 'Last Purchase Order',
      sortable: true,
    },
    {
      key: 'rank',
      label: 'Ranking',
      sortable: true,
    },
    { key: 'status', sortable: true },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalVendors = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search ?? '')
  const sortBy = ref(route.query.sortBy ?? 'stringID')
  const isSortDirDesc = ref(route.query.sortDesc === 'true')
  const statusFilter = ref([])
  if (Array.isArray(route.query.status)) {
    statusFilter.value = route.query.status
  } else if (route.query.status) {
    statusFilter.value = [route.query.status]
  }
  const toDeletedID = ref(null)
  const type = ref('approved')
  const vendorData = ref([])
  const createdAtFilter = ref(route.query.createdAt || '')
  let initialLoad = true

  const dataMeta = computed(() => {
    const localItemsCount = refApprovedVendorsTable.value ? refApprovedVendorsTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalVendors.value,
    }
  })

  const refetchData = () => {
    refApprovedVendorsTable.value.refresh()
  }

  watch([searchQuery, statusFilter, createdAtFilter], () => {
    currentPage.value = 1
    refetchData()
  }, { deep: true })

  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchVendors = (ctx, callback) => {
    store
      .dispatch('app-approved-vendors/fetchVendors', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
        createdAt: createdAtFilter.value,
        type: type.value,
      },
      { root: true })
      .then(response => {
        const { vendors, total } = response.data
        vendorData.value = vendors
        callback(vendors)
        totalVendors.value = total
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              status: statusFilter.value,
              createdAt: createdAtFilter.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteVendor = () => {
    store
      .dispatch('app-approved-vendors/deleteVendor', {
        id: toDeletedID.value,
      },
      { root: true })
      .then(response => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message ?? '',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveVendorStatusVariant = status => {
    if (status === 'approved') return 'success'
    if (status === 'draft') return 'secondary'
    if (status === 'pending') return 'warning'
    if (status === 'non approved') return 'danger'
    return 'primary'
  }

  return {
    fetchVendors,
    tableColumns,
    perPage,
    currentPage,
    totalVendors,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refApprovedVendorsTable,

    resolveVendorStatusVariant,
    refetchData,

    toDeletedID,
    deleteVendor,
    vendorData,

    // Extra Filters
    statusFilter,
    createdAtFilter,
  }
}
